<template>
    <div>
        <div class="flex flex-col flex-1 h-full px-2">
            <div class="bg-gray-100 p-4 mb-6">
                <div class="form-row">
                    <div class="form-col">
                        <label for="email">property email address *</label>
                        <text-input id="email" v-model="contact.email" />
                        <span class="form-hint">All Emails from contact forms will be sent to this email address.</span>
                        <span v-if="hasError('contact.email')" class="form-error">{{ showError('contact.email') }}</span>
                    </div>
                    <div class="form-col">
                        <label for="phone">property phone number *</label>
                        <text-input id="phone" v-model="contact.phone" />
                        <span class="form-hint">This phone number will be listed on the website.</span>
                        <span v-if="hasError('contact.phone')" class="form-error">{{ showError('contact.phone') }}</span>
                    </div>
                </div>
            </div>

            <hours-of-operations :website-operation-hours="initHours" @operation-hours-change="updateHours"></hours-of-operations>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import ModalFooter from "@/components/ui/modals/ModalFooter";
    import HoursOfOperations from "@/components/operation_hours/HoursOfOperations";
    import TextInput from '@/components/ui/TextInput';

    export default {
        mixins: [ ModalNavigation ],
        components: { ModalFooter, HoursOfOperations, TextInput },
        data: () => {
            return {
                website: {},
                contact: {
                    email: null,
                    phone: null,
                    hours: []
                },
                contactInformationAlertId: 'contact-information-alert'
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteById: 'websites/getWebsiteById',
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
            initHours() {
                return this.website.hours?.map(({ ...record }) => ({ ...record })) || [];
            },
        },
        methods: {
            ...mapActions({
                updateWebsiteInStore: 'websites/updateWebsite',
                clearWebsiteInformation: 'website_info/clearWebsiteInformation',
                notifySuccessBack: 'alerts/notifySuccessBack',
                notifyError: 'alerts/notifyError',
                addWebsiteInfo: 'website_info/addWebsiteInfo',
            }),
            updateHours(payload) {
                this.contact.hours = payload;
            },
            save() {
                if(this.validator.passes()) {
                    if (this.validateHours()) {
                        const payload = {};
                        payload.production_url = this.website.production_url;
                        payload.customers_id = this.website.customers_id;
                        payload.communities_id = this.website.communities_id;
                        payload.id = this.website.id;
                        payload.contact_phone = this.contact.phone;
                        payload.contact_email = this.contact.email;
                        payload.enable_digital_human = this.website.enable_digital_human;
                        payload.hours = JSON.stringify(this.contact.hours);
                        payload.custom_links = this.website.custom_links;

                        this.$cmsDataProvider.update('updateWebsite', {data: payload, id: this.website.id})
                            .then(() => {
                                this.notifySuccessBack('The contact information was updated successfully');
                            }).catch(() => {
                            this.notifyError('There was an error saving the information');
                        });
                        this.ReloadWebsiteInfo();
                    }
                }
            },
            async ReloadWebsiteInfo() {
                this.clearWebsiteInformation();
                await this.$cmsDataProvider.get('websitebyId', {websiteId: this.$route.params.id})
                .then((response) => {
                    if(!response.message) {
                        this.website = response
                        this.addWebsiteInfo(response);
                    }else{
                        this.notifyError('Website not found');
                    }
                }).catch(() => {
                    this.notifyError('There was an error getting the website ');
                    this.website = null
                });
            },
            validateHours() {
                let valid = true

                this.contact.hours.forEach(hour => {
                    if (! hour.description.length) {
                        valid = false
                    }
                })

                if (!valid) {
                    this.notifyError('You must specify the days for all contact hours.');
                }

                return valid
            }
        },

        validations: {
            'contact.email' : 'required | email',
            'contact.phone' : 'required',
        },
        created() {
            this.initValidator();
        },
        async mounted() {
            this.website = this.getWebsiteInfo

            this.contact.email = this.website.contactInformation.contact_email;
            this.contact.phone = this.website.contactInformation.contact_phone;
            this.contact.hours = this.website.hours;
        },
    }
</script>
